import React from "react";
import { useSaveSettings } from "../../data/settingsApi";
import { navigate } from "gatsby";
import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  Heading,
  Input,
  Link,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import { ChevronRightIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import Layout from "../../components/Layout";

export const DotProgress = ({
  progress,
  totalNum,
  onDotClick,
}: {
  progress: number;
  totalNum: number;
  onDotClick: (index: number) => void;
}) => {
  return (
    <main aria-label="Get started">
      <Center>
        <Flex>
          {Array.from(Array(totalNum).keys()).map((i) => {
            if (i + 1 === progress) {
              return (
                <Flex
                  key={i}
                  mr="0.2rem"
                  onClick={() => onDotClick(i + 1)}
                  _hover={{ cursor: "pointer" }}
                >
                  <Box
                    w={2}
                    h={2}
                    bg={i < progress ? "blue.500" : "gray.300"}
                    borderLeftRadius="50%"
                  />
                  <Box
                    w={0.1}
                    h={2}
                    bg={i < progress ? "blue.500" : "gray.300"}
                  />
                  <Box
                    w={2}
                    h={2}
                    bg={i < progress ? "blue.500" : "gray.300"}
                    borderRightRadius="50%"
                  />
                </Flex>
              );
            } else {
              return (
                <Box
                  key={i}
                  w={2}
                  h={2}
                  borderRadius="50%"
                  bg={i < progress ? "blue.500" : "gray.300"}
                  mr="0.2rem"
                  onClick={() => onDotClick(i + 1)}
                  _hover={{ cursor: "pointer" }}
                />
              );
            }
          })}
        </Flex>
      </Center>
    </main>
  );
};

const Onboarding = () => {
  const saveSettings = useSaveSettings();
  const [isMobile] = useMediaQuery("(max-width: 540px)");

  const completeOnboarding = () => {
    saveSettings
      .mutateAsync({ onboarded: true, preferredName: name ?? "" })
      .then(() => {
        navigate("/73281");
      });
  };

  const page1 = (
    <>
      <Heading>Welcome to IBPI</Heading>
      <Text>
        IBPI stands for the 'Integrated Bipolar Parenting Intervention'.
      </Text>
      <Text>
        This intervention is part of a research study led by Lancaster
        University, and is a digital resource with guidance on parenting for
        those who experience bipolar disorder. It includes 9 modules, covering
        areas such as managing mood changes, dealing with your child’s difficult
        behaviour, and managing relationships. The website contains videos and
        interactive exercises, as well as information on bipolar disorder and
        parenting support.
      </Text>
      <Text>
        It has been created by a team of experts including parents with bipolar,
        academics, and clinicians. You can find out more about the IBPI
        team&nbsp;
        <Link
          href="https://www.lancaster.ac.uk/health-and-medicine/research/spectrum/research/ibpi/the-team/"
          isExternal
          color={"blue.500"}
        >
          here <ExternalLinkIcon />
        </Link>
        .
      </Text>
      <Text>
        We have created the IBPI tool with the aim of helping people with
        bipolar disorder with their parenting. We hope this will improve their
        children’s behavioural and emotional outcomes, improve the parents’ mood
        and confidence, and improve overall family functioning. The current
        study seeks to understand if IBPI works and if it is cost effective. If
        successful, our aim is to make the IBPI site available for parents with
        bipolar across the UK.
      </Text>
    </>
  );

  const page2 = (
    <>
      <Heading>Using IBPI</Heading>
      <Text>
        IBPI has been designed to be accessible 24/7, 7 days a week, so you have
        the flexibility to look at it whenever you’d like. As it is a digital
        resource, it can be accessed easily both at home and on the go from
        either your computer or mobile phone. All you need is an internet
        connection.
      </Text>
      <Text>
        The modules of the IBPI are set out in a logical order, but you are free
        to work through them in any order you wish. You can also revisit any
        module as often as you like – in fact we recommend you do, as this can
        be helpful to solidify what you have worked on.
      </Text>
      <Text>
        It is important to note that IBPI has been designed to be used alongside
        the support you already receive and is not intended to replace any form
        of medical care.
      </Text>
    </>
  );

  const [name, setName] = React.useState<string>("");
  const page3 = (
    <form key="prefNameForm">
      <Heading mb="20px">What should we call you?</Heading>
      <Text mb="10px">
        This can be different than your name and will be used within the app.
      </Text>
      <Input
        aris-label="Preferred name"
        placeholder="Your preferred name"
        focusBorderColor="blue.400"
        value={name}
        onChange={(e) => {
          setName(e.target.value);
        }}
        key="inputPrefName"
        autoFocus={true}
        mb="20px"
      />
    </form>
  );

  const page4 = (
    <>
      <Heading>How to use IBPI</Heading>
      <Tabs variant="solid-rounded" isFitted defaultIndex={isMobile ? 1 : 0}>
        <TabList>
          <Tab>Desktop</Tab>
          <Tab>Mobile</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <iframe
              title="siteTutorial"
              src="https://player.vimeo.com/video/781463688?h=c271061497&badge=0&autopause=0&player_id=0&app_id=58479/embed%22"
              width="100%"
              height="360"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen={false}
            />
          </TabPanel>
          <TabPanel>
            {/*PUT THE EMBED LINK FOR MOBILE HOW TO HERE*/}
            <iframe
              title="siteTutorial"
              src="https://player.vimeo.com/video/786526212?h=690d75699a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              width="100%"
              height="360"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen={false}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );

  const pages = [page1, page2, page3, page4];
  const [progress, setProgress] = React.useState<number>(0);

  return (
    <Layout plain={true}>
      <Center>
        <Container w="full" h="full" my={10}>
          <VStack align="left" gap={2}>
            {pages[progress]}
          </VStack>
          <Flex mt={3}>
            <DotProgress
              progress={progress + 1}
              totalNum={pages.length}
              onDotClick={(newI) => {
                setProgress(newI - 1);
              }}
            />
            <Spacer />
            <Button
              colorScheme={progress === pages.length - 1 ? "blue" : "green"}
              aris-label="Next"
              rightIcon={<ChevronRightIcon />}
              onClick={() => {
                if (progress === pages.length - 1) {
                  completeOnboarding();
                } else {
                  setProgress(progress + 1);
                }
              }}
            >
              {progress === pages.length - 1 ? "Let's go!" : "Next"}
            </Button>
          </Flex>
        </Container>
      </Center>
    </Layout>
  );
};

export default Onboarding;
